//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Selector",
  props: ["list", "selected"],
  data() {
    return {
      open: false,
      selectedIndex: 0,
      selectedItem: null,
    };
  },
  methods: {
    itemClicked(index) {
      this.open = false;
      // setTimeout(() => {
      this.selectedIndex = index;
      this.selectedItem = this.list[this.selectedIndex];
      this.$emit("change", this.selectedItem);
      // }, 100);
    },
    close() {
      this.open = false;
    },
    toggleList(event) {
      event.stopPropagation();
      event.preventDefault();
      this.open = !this.open;
    },
    setList() {},
  },
  computed: {
    selectedTitle() {
      return this.selectedItem ? this.selectedItem.data.title[0].text : "Menu";
    },
  },
  watch: {
    list() {
      // this.selectedItem = this.list[0];
    },
    selected: function (value) {
      // this.selectedItem = value;
      this.selectedIndex = this.list.findIndex((item) => item === value);
      if (this.selectedIndex === -1) {
        this.selectedItem = null;
      } else {
        this.selectedItem = this.list[this.selectedIndex];
      }
    },
    open: function (value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs["list"].scrollTop = 0;
        });
        document.addEventListener("click", this.close);
      } else {
        document.removeEventListener("click", this.close);
      }
    },
  },

  beforeMount() {
    if (this.selected) {
      this.selectedItem = this.selected;
      // } else {
      // this.selectedItem = this.list[0];
    }
  },
  mounted() {},
};
