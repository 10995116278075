const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const state = () => ({
  projects: [],
  codeDoodles: [],
  activeMediaIndex: 0,
  appTitle: "Lasse Korsgaard",
  appTitleDivider: "–"
});

export const mutations = {
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
  SET_CODE_DOODLES(state, codeDoodles) {
    state.codeDoodles = codeDoodles;
  },
  SET_ERROR(state, error) {
    state.menu = error;
  },
  SET_ACTIVE_MEDIA_INDEX(state, index) {
    state.activeMediaIndex = index;
  }
};

export const actions = {
  updateMediaIndex({ commit }, index) {
    commit("SET_ACTIVE_MEDIA_INDEX", index);
  },
  async fetchProjectsList({ commit }, $prismic) {
    try {
      // const projects = (await $prismic.api.getSingle('projects')).data
      const projects = await $prismic.api.getSingle("projects", {
        fetchLinks: [
          "project.media",
          "project.featured_image",
          "project.title",
          "project.date",
          "project.featured_image_anmation",
          "project.animated_featured_image",
          "projects.intro_text",
          "projects.intro_availability"
        ]
      });

      let list = projects.data.projects_list.map(item => {
        const data = item.project.data;

        item.project.data = data;
        return item.project;
      });

      commit("SET_PROJECTS", list);
    } catch (e) {
      const error = "No projects";

      commit("SET_ERROR", error);
    }
  },
  async fetchCodeDoodlesList({ commit }, $prismic) {
    try {
      // const projects = (await $prismic.api.getSingle('projects')).data
      const codeDoodles = await $prismic.api.getSingle("code_doodles", {
        fetchLinks: [
          "code_doodle.media",
          "code_doodle.featured_image",
          "code_doodle.title",
          "code_doodle.date"
        ]
      });

      // console.log(codeDoodles);

      // let list = projects.data.projects_list.map(item => {
      //   const data = item.project.data;

      //   item.project.data = data;
      //   return item.project;
      // });
      let list = codeDoodles.data.code_doodles_list.map(item => {
        // return item.code_doodle
        // last_publication_date;
        const old = item.code_doodle;

        const newItem = {
          ...old
        };

        if (newItem.data.featured_image.thumbnail) {
          newItem.data.thumbnail = newItem.data.featured_image.thumbnail.url;
        } else {
          newItem.data.thumbnail = newItem.data.featured_image.url;
        }

        const date = new Date(old.last_publication_date);

        newItem.date = `${
          monthNames[date.getMonth()]
        } ${date.getDate()} ${date.getFullYear()}`;

        return newItem;
      });

      // console.log(list);

      commit("SET_CODE_DOODLES", list);
    } catch (e) {
      const error = "No projects";

      commit("SET_ERROR", error);
    }
  }
};
