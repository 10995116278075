//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Selector from "../components/Selector.vue";
export default {
  components: {
    Selector,
  },
  beforeMount() {
    let uid = this.$nuxt.$route.params.uid;
    if (uid === undefined) {
      uid = this.$nuxt.$route.name;
    }
    this.selectedProject = this.projects.find((project) => project.uid === uid);
  },
  mounted() {},
  data() {
    return {
      selectedProject: null,
      activeMediaIndex: 0,
      numMedia: 1,
    };
  },
  computed: {
    projects() {
      const info = {
        uid: "info",
        slug: "/info",
        data: {
          title: [{ text: "Info" }],
        },
      };
      const pages = [info, ...this.$store.state.projects];

      const codeDoodles = {
        uid: "code-doodles",
        slug: "/code-doodles",
        data: {
          title: [{ text: "Code Doodles" }],
        },
      };

      // pages.push(codeDoodles);

      return pages;
    },
  },
  watch: {
    activeMediaIndex(newIndex) {
      this.$store.dispatch("updateMediaIndex", newIndex);
    },
    $route() {
      // console.log(this.$route.path === "/");
      if (this.$route.path === "/") {
        this.selectedProject = null;
      } else if (this.$route.path.indexOf("/projects/") > -1) {
        const uid = this.$route.path.slice(10);
        this.selectedProject = this.projects.find(
          (project) => project.uid === uid
        );
      } else {
        const uid = this.$route.path.slice(1);
        this.selectedProject = this.projects.find(
          (project) => project.uid === uid
        );
      }
    },
  },
  methods: {
    selectorChange(data) {
      this.selectedProject = data;

      let slug = this.selectedProject.slug;
      if (this.selectedProject.type === "project") {
        slug = `/projects/${this.selectedProject.slug}`;
      }
      this.$router.push({
        path: slug,
      });
    },
  },
  async middleware({ store, $prismic }) {
    await store.dispatch("fetchCodeDoodlesList", $prismic);
    await store.dispatch("fetchProjectsList", $prismic);
  },
};
