/**
 * To learn more about Link Resolving check out the Prismic documentation
 * https://prismic.io/docs/vuejs/beyond-the-api/link-resolving
 */

export default function(doc) {
  if (doc.type === "about" || doc.type === "info" || doc.type === "contact") {
    return "/" + doc.uid;
  }

  if (doc.type === "project") {
    return "/projects/" + doc.uid;
  } else if (doc.isBroken) {
    return "/not-found";
  }

  if (doc.type === "homepage") {
    return "/";
  }

  if (doc.type === "projects") {
    return "/";
  }

  return "/not-found";
}
