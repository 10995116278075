// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/GT-America-Extended-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/GT-America-Standard-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/fonts/GT-America-Standard-Bold.woff2");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");font-family:\"GT America Extended Bold\";font-weight:100}@font-face{src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");font-family:\"GT America Standard Regular\";font-weight:100}@font-face{src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");font-family:\"GT America Standard Bold\";font-weight:100}*{-webkit-tap-highlight-color:transparent;outline:none;box-sizing:border-box;font-family:\"GT America Standard Regular\",sans-serif;text-decoration:none;font-size:16px;touch-action:manipulation}::selection{background:#00f;color:#fff}::-moz-selection{background:#00f;color:#fff}a{color:#000}h3{margin-top:1em;margin-bottom:0}p{margin-top:0;margin-bottom:1em;line-height:1.4}body,html{margin:0;background:#e5e5e5;color:#00f}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
