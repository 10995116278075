// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/GT-America-Extended-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/GT-America-Standard-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/fonts/GT-America-Standard-Bold.woff2");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");font-family:\"GT America Extended Bold\";font-weight:100}@font-face{src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");font-family:\"GT America Standard Regular\";font-weight:100}@font-face{src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");font-family:\"GT America Standard Bold\";font-weight:100}*[data-v-54221750]{-webkit-tap-highlight-color:transparent;outline:none;box-sizing:border-box;font-family:\"GT America Standard Regular\",sans-serif;text-decoration:none;font-size:16px;touch-action:manipulation}[data-v-54221750]::selection{background:#00f;color:#fff}[data-v-54221750]::-moz-selection{background:#00f;color:#fff}a[data-v-54221750]{color:#000}h3[data-v-54221750]{margin-top:1em;margin-bottom:0}p[data-v-54221750]{margin-top:0;margin-bottom:1em;line-height:1.4}.page[data-v-54221750]{width:100vw;height:100vh;max-width:1800px;margin:0 auto;position:relative;padding:40px;display:flex;flex-direction:column}@media only screen and (max-width:960px){.page[data-v-54221750]{padding:20px}}.page header[data-v-54221750]{width:100%;background:#fff;height:40px;padding:0;display:flex;justify-content:space-between;align-items:center;position:relative;z-index:1000;margin-bottom:20px}.page header .logo[data-v-54221750]{margin-left:10px}.page header .logo h1[data-v-54221750]{display:inline-block;font-weight:400;margin-right:20px}.page header .logo span.description[data-v-54221750]{color:grey}@media only screen and (max-width:960px){.page header .logo span.description[data-v-54221750]{display:none}}@media only screen and (max-width:740px){.page header[data-v-54221750]{flex-direction:column;height:60px}.page header .logo[data-v-54221750]{display:block;width:100%}.page header .selector[data-v-54221750]{width:100%}}.page main[data-v-54221750]{flex:1}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
